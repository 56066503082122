import DatePicker from 'vue2-datepicker';
import appStrings from '@/app/utility/string.utility';
export default {
  name: 'advanceSearch',
  components: {
    DatePicker
  },
  data() {
    return {
      selectedGridData: null,
      showValueSetModal: null,
      parent_value_set_id: null,
      setTimeVsetCode: null,
      valueSetCode: null,
      historyType: null,
      showHistory: false,
      unsubscribe: null,
      loader: false,
      showAddIntWorkflow: false,
      valueSetName: null,
      searchForm: {
        fromDate: null,
        toDate: null,
        start_date_end_date: null,
        type: {
          value_code: null,
          value_meaning: null,
          value_set_dtl_id: null
        },
        category: {
          value_code: null,
          value_meaning: null,
          value_set_dtl_id: null
        },
        subcategory: {
          value_code: null,
          value_meaning: null,
          value_set_dtl_id: null
        },
        legalEntity: {
          leName: null,
          leId: null
        },
        projectType: {
          value_code: null,
          value_meaning: null
        },
        localtion: {
          locName: null,
          locId: null
        },
        actionTaken: false,
        subject: null,
        number: null
      }
    };
  },
  mounted() {},
  methods: {
    search() {
      this.$emit('setSearchParams', this.searchForm);
    },
    clear() {},
    openValueSetModal(vsetCode) {
      this.parent_value_set_id = null;
      if (vsetCode === appStrings.VALUESETTYPE.NFA_SUB_CATEGORY || vsetCode === appStrings.VALUESETTYPE.NFA_LOCATION) {
        this.parent_value_set_id =
          vsetCode === appStrings.VALUESETTYPE.NFA_SUB_CATEGORY
            ? this.searchForm.category.value_set_dtl_id.toString()
            : this.searchForm.legalEntity.leId;
      }
      this.valueSetCode = vsetCode;
      this.showValueSetModal = true;
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    selectedvalueSet(item) {
      if (this.valueSetCode === 'NFA_TYPE') {
        this.searchForm.type.value_code = item.value_code;
        this.searchForm.type.value_meaning = item.value_meaning;
        this.searchForm.type.value_set_dtl_id = item.value_set_dtl_id;
      } else if (this.valueSetCode === 'NFA_CATEGORY') {
        this.searchForm.category.value_code = item.value_code;
        this.searchForm.category.value_meaning = item.value_meaning;
        this.searchForm.category.value_set_dtl_id = item.value_set_dtl_id;
      } else if (this.valueSetCode === appStrings.VALUESETTYPE.NFA_SUB_CATEGORY) {
        this.searchForm.subcategory.value_code = item.value_code;
        this.searchForm.subcategory.value_meaning = item.value_meaning;
        this.searchForm.subcategory.value_set_dtl_id = item.value_set_dtl_id;
      } else if (this.valueSetCode === appStrings.VALUESETTYPE.LEGAL_ENTITY_LIST) {
        this.searchForm.legalEntity.leName = item.org_name;
        this.searchForm.legalEntity.leId = item.org_id;
      } else if (this.valueSetCode === 'NFA_PROJECT_TYPES') {
        this.searchForm.projectType.value_code = item.value_code;
        this.searchForm.projectType.value_meaning = item.value_meaning;
      } else if (this.valueSetCode === appStrings.VALUESETTYPE.NFA_LOCATION) {
        this.searchForm.localtion.locName = item.loc_name;
        this.searchForm.localtion.locId = item.loc_id;
      }
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    }
  },
  beforeDestroy() {
    clearTimeout(this.setTimeVsetCode);
  }
};
