import appStrings from '@/app/utility/string.utility';
import DatePicker from 'vue2-datepicker';
import party from '../../../generalLedger/party';
import { format } from 'date-fns';
export default {
  name: 'actionTaken',
  components: {
    DatePicker,
    party
  },
  props: ['wfHeaderId', 'selectedGridData'],
  data() {
    return {
      editMode: true,
      showAccountingModal: false,
      unsubscribe: null,
      showVendorModal: false,
      setTimeVsetCode: null,
      actionTakenIndex: null,
      showValueSetModal: false,
      parent_value_set_id: null,
      wfEventId: null,
      loader: false,
      legalEntityId: 0,
      orgName: null,
      isSuccess: false,
      showAlert: false,
      respMessage: null,
      defaultActionTakenObj: {
        ou_id: null,
        ou_name: null,
        erp_org_name: null,
        vendor_site_id: null,
        tax_category_id: null,
        nfa_hdr_id: null,
        location_id: null,
        payment_term_id: null,
        gl_date: new Date(),
        transactional_currency_lookup: 'INR',
        nfa_sub_cat_vset: this.selectedGridData.nfa_sub_cat_vset,
        nfa_number: this.selectedGridData.nfa_number,
        key_flexfield_comb_id: null,
        concatenated_segment: null,
        interface_ref_id: null,
        payment_bank_name: null,
        benefitiary_bank_account: null,
        payment_term_meaning: null,
        benefitiary_ifsc_code: null,
        erp_vendor_site_name: null,
        line_accounting_date: null,
        vendor_site_name: null,
        erp_tax_cat_name: null,
        erp_vendor_number: null,
        dist_set_name: null,
        erp_payment_status: null,
        action_date: new Date(),
        pay_group: 'RealApp',
        erp_site_id: null,
        action_ref: null,
        action_comment: null,
        invoice_code: null,
        vedor_name: null,
        vendor_id: null,
        nfa_action_id: 0,
        awarded_amount: null,
        invoice_meaning: null,
        erp_vendor_id: null,
        erp_status: 'DRAFT',
        action_code: null,
        action_meaning: null,
        action_vset_dtl_id: null,
        payment_method: 'EFT',
        utr_number: null,
        tax_category: null,
        inventory_id: null,
        inventory_name: null,
        line_no: 1,
        terms_name: 'Immediate',
        gst_applicable: false,
        erp_inv_name: null,
        erp_invoice_id: null,
        location_name: null,
        erp_loc_name: null,
        invoice_date: new Date(),
        invoice_source: 'RealApp'
      },
      actionTakenList: [],
      actionTakenFields: [
        {
          key: 'action_meaning',
          label: 'Action Type'
        },
        {
          key: 'action_date'
        },
        {
          key: 'action_ref'
        },
        {
          key: 'action_comment'
        },
        {
          key: 'nfa_sub_cat_vset',
          label: 'Expense Type'
        },
        {
          key: 'ou_name',
          label: 'Organization'
        },
        {
          key: 'erp_org_name',
          label: 'ERP ORG Name'
        },
        {
          key: 'location_name',
          label: 'Location'
        },
        {
          key: 'erp_loc_name',
          label: 'ERP Loc Name'
        },
        {
          key: 'inventory_name',
          label: 'Inventory'
        },
        {
          key: 'erp_inv_name',
          label: 'ERP Inv Name'
        },
        {
          key: 'vedor_name',
          label: 'Vendor'
        },
        {
          key: 'vendor_site_name',
          label: 'Vendor Site'
        },

        {
          key: 'erp_vendor_number',
          label: 'ERP Ven Num'
        },
        {
          key: 'erp_vendor_site_name',
          label: 'ERP Site Name'
        },
        {
          key: 'invoice_meaning',
          label: 'Invoice Type'
        },
        {
          key: 'invoice_source'
        },
        {
          key: 'nfa_number',
          label: 'Invoice Number'
        },
        {
          key: 'invoice_date',
          label: 'Invoice Date'
        },
        {
          key: 'awarded_amount',
          label: 'Invoice Amount'
        },
        {
          key: 'invoice_currency',
          label: 'Invoice Currency'
        },
        {
          key: 'gl_date',
          label: 'GL Date'
        },
        {
          key: 'pay_group'
        },
        {
          key: 'payment_method'
        },
        {
          key: 'terms_name'
        },
        {
          key: 'payment_term_meaning',
          label: 'Payment Term'
        },

        {
          key: 'transactional_currency_lookup',
          label: 'Payment Currency'
        },
        {
          key: 'line_no',
          label: 'Line Number'
        },
        {
          key: 'line_amount',
          label: 'Line Amount'
        },
        {
          key: 'line_accounting_date',
          label: 'Line Accounting Date'
        },
        {
          key: 'concatenated_segment',
          label: 'Accounting'
        },

        {
          key: 'dist_set_name',
          label: 'Dist Set Name'
        },
        {
          key: 'erp_status',
          label: 'ERP Status'
        },
        {
          key: 'gst_applicable',
          label: 'GST Applicable'
        },
        {
          key: 'tax_category'
        },
        {
          key: 'erp_tax_cat_name',
          label: 'ERP Tax Cat Name'
        },
        {
          key: 'interface_ref_id'
        },
        {
          key: 'erp_invoice_id',
          label: 'ERP Invoice ID'
        },
        {
          key: 'erp_payment_status',
          label: 'ERP Payment Status'
        },
        {
          key: 'utr_number',
          label: 'UTR Num'
        },
        {
          key: 'payment_bank_name',
          label: 'Payment Bank'
        },
        {
          key: 'benefitiary_bank_account'
        },
        {
          key: 'benefitiary_ifsc_code',
          label: 'Benefitiary IFSC Code'
        },

        {
          key: 'add'
        },
        {
          key: 'remove'
        }
      ],
      showTaxCatModal: false,
      taxCategoryList: [],
      taxCategoryListFields: [
        {
          key: 'category_name'
        }
      ],
      accountingList: [],
      accountingFields: [
        {
          key: 'segment_code'
        },
        {
          key: 'segment_meaning'
        }
      ]
    };
  },
  mounted() {
    this.getActionTaken();
    this.getTaxCategoryList();
    this.eventBus.$on('setVendorData', vendorData => {
      this.setVendorData(vendorData);
    });
  },
  methods: {
    saveActionTaken() {
      const actionTakenData = this.actionTakenList.map(data => {
        return {
          action_code: data.action_code,
          action_comment: data.action_comment,
          action_date: data.action_date
            ? format(new Date(data.action_date), 'dd-MMM-yyyy')
            : null,
          action_ref: data.action_ref,
          awarded_amount: data.awarded_amount,
          dist_set_name: data.dist_set_name,
          gl_date: data.gl_date
            ? format(new Date(data.gl_date), 'dd-MMM-yyyy')
            : null,
          gst_applicable: data.gst_applicable,
          inventory_id: data.inventory_id,
          invoice_code: data.invoice_code,
          invoice_date: data.invoice_date
            ? format(new Date(data.invoice_date), 'dd-MMM-yyyy')
            : null,
          invoice_source: data.invoice_source,
          key_flexfield_comb_id: data.key_flexfield_comb_id,
          line_accounting_date: data.line_accounting_date
            ? format(new Date(data.line_accounting_date), 'dd-MMM-yyyy')
            : null,
          line_no: data.line_no,
          location_id: data.location_id,
          nfa_action_id: data.nfa_action_id,
          ou_id: data.ou_id,
          tax_category_id: data.tax_category_id,
          transactional_currency_lookup: 'INR',
          vendor_id: data.vendor_id,
          vendor_site_id: data.vendor_site_id,
          payment_term_id: data.payment_term_id
        };
      });
      const payload = {
        actionTaken: {
          action_taken_list: actionTakenData
        },
        nfHdrId: this.selectedGridData.nfa_hdr_id
      };
      this.loader = true;
      this.$store
        .dispatch('intWorkflow/actionTaken', payload)
        .then(response => {
          this.loader = false;
          this.showAlert = true;
          if (response.status === 200) {
            this.editMode = false;
            this.isSuccess = true;
            this.respMessage = response.data.message;
          } else {
            this.isSuccess = false;
            this.respMessage = response.response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    setVendorData(vendorData) {
      this.actionTakenList[this.actionTakenIndex].erp_vendor_number =
        vendorData.vendorData.erp_vendor_number;
      this.actionTakenList[this.actionTakenIndex].erp_vendor_site_name =
        vendorData.vendorData.erp_vendor_site_name;
      this.actionTakenList[this.actionTakenIndex].vendor_site_name =
        vendorData.vendorData.site_name;
      this.actionTakenList[this.actionTakenIndex].vendor_site_id =
        vendorData.vendorData.vendor_site_id;
      this.actionTakenList[this.actionTakenIndex].vendor_id =
        vendorData.vendorId;
      this.actionTakenList[
        this.actionTakenIndex
      ].vedor_name = this.$store.state.party.partyName;
      this.showVendorModal = false;
    },
    getActionTaken() {
      this.loader = true;
      this.$store
        .dispatch('intWorkflow/getActionTake', this.wfHeaderId)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.editMode = response.data.data.length > 0 ? false : true;
            const actionData = response.data.data.map(data => {
              data.action_date = data.action_date
                ? new Date(data.action_date)
                : null;
              data.gl_date = data.gl_date ? new Date(data.gl_date) : null;
              data.invoice_date = data.invoice_date
                ? new Date(data.invoice_date)
                : null;
              data.line_accounting_date = data.line_accounting_date
                ? new Date(data.line_accounting_date)
                : null;
              return data;
            });
            this.actionTakenList =
              response.data.data.length > 0
                ? actionData
                : [this.defaultActionTakenObj];
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    addNewRow() {
      if (this.editMode) {
        this.actionTakenList.push(this.defaultActionTakenObj);
      }
    },
    removeRow(index) {
      this.actionTakenList.splice(index, 1);
    },
    selectedvalueSet(item) {
      if (this.vsetCode === 'ACTION_TYPE') {
        this.actionTakenList[this.actionTakenIndex].action_code =
          item.value_code;
        this.actionTakenList[this.actionTakenIndex].action_meaning =
          item.value_meaning;
        this.actionTakenList[this.actionTakenIndex].action_vset_dtl_id =
          item.value_set_dtl_id;
      } else if (this.vsetCode === 'INVOICE_TYPE') {
        this.actionTakenList[this.actionTakenIndex].invoice_code =
          item.value_code;
        this.actionTakenList[this.actionTakenIndex].invoice_meaning =
          item.value_meaning;
      } else if (this.vsetCode === 'FMS_PAYMENT_TERM') {
        this.actionTakenList[this.actionTakenIndex].payment_term_meaning =
          item.payment_term_name;
        this.actionTakenList[this.actionTakenIndex].payment_term_id =
          item.payment_term_id;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.OU_LIST) {
        this.actionTakenList[this.actionTakenIndex].ou_name = item.org_name;
        this.actionTakenList[this.actionTakenIndex].ou_id = item.org_id;
        this.actionTakenList[this.actionTakenIndex].erp_org_name =
          item.erp_org_name;
        this.legalEntityId = item.org_parent_id;
        this.orgName = item.org_name;
        this.getFlexFieldAccounting();
      } else if (this.vsetCode === appStrings.VALUESETTYPE.LOCATION) {
        this.actionTakenList[this.actionTakenIndex].location_name =
          item.loc_name;
        this.actionTakenList[this.actionTakenIndex].location_id = item.loc_id;
        if (item.erp_loc_name) {
          this.actionTakenList[this.actionTakenIndex].erp_loc_name =
            item.erp_loc_name;
        }
      } else if (this.vsetCode === appStrings.VALUESETTYPE.INVENTORY_ORG_LIST) {
        this.actionTakenList[this.actionTakenIndex].inventory_name =
          item.inv_name;
        this.actionTakenList[this.actionTakenIndex].inventory_id = item.inv_id;
        this.actionTakenList[this.actionTakenIndex].erp_inv_name =
          item.erp_inv_name;
      }
      this.showValueSetModal = false;
    },
    openValueSetModal(vsetCode, index) {
      this.vsetCode = vsetCode;
      this.actionTakenIndex = index;
      this.showValueSetModal = true;
      this.parent_value_set_id = null;
      if (vsetCode === 'INVOICE_TYPE') {
        this.parent_value_set_id = this.actionTakenList[
          this.actionTakenIndex
        ].action_vset_dtl_id;
      } else if (vsetCode === appStrings.VALUESETTYPE.OU_LIST) {
        this.parent_value_set_id = this.selectedGridData.le_id;
      } else if (vsetCode === appStrings.VALUESETTYPE.INVENTORY_ORG_LIST) {
        this.parent_value_set_id = this.actionTakenList[
          this.actionTakenIndex
        ].ou_id;
      }
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    hideTaxCateModal(flag, index) {
      this.actionTakenIndex = index;
      this.showTaxCatModal = flag;
    },
    getTaxCategoryList() {
      this.$store.dispatch('fms/getTaxCatory').then(response => {
        if (response.status === 200) {
          this.taxCategoryList = response.data.data;
        }
      });
    },
    categoryRowSelected(item) {
      this.actionTakenList[this.actionTakenIndex].tax_category_id =
        item.tax_cat_hdr_id;
      this.actionTakenList[this.actionTakenIndex].tax_category =
        item.category_name;
      this.showTaxCatModal = false;
    },
    hideVendorModal(flag, index) {
      this.actionTakenIndex = index;
      this.showVendorModal = flag;
    },
    getFlexFieldAccounting() {
      const payload = {
        le_id: this.legalEntityId,
        segment_code: '',
        segment_meaning: this.orgName
      };
      this.$store
        .dispatch('intWorkflow/getFlexFieldAccounting', payload)
        .then(response => {
          if (response.status === 200) {
            this.accountingList = response.data.data;
          }
        });
    },
    accountingRowSelected(item) {
      this.actionTakenList[this.actionTakenIndex].key_flexfield_comb_id =
        item.ccid;
      this.actionTakenList[this.actionTakenIndex].concatenated_segment =
        item.segment_meaning;
      this.showAccountingModal = false;
    },
    hideAccountingModal(flag, index) {
      if (!this.actionTakenList[index].ou_name) {
        return alert(appStrings.DEPENDENTVSETMSG.ORGNAMEMSG);
      }
      this.actionTakenIndex = index;
      this.showAccountingModal = flag;
    }
  },
  destroyed() {
    this.eventBus.$off('setVendorData');
  }
};
