import commonHelper from '@/app/utility/common.helper.utility';
import addInternalWorkflow from '@/app/components/onlineApproval/internalWorkFlow/addInternalWorkflow';
import HistoryRecord from './history/historyRecord';
import ApprovalHistory from './history/approvalHistory';
import UploadDocument from './history/uploadDocument';
import sendForApproval from './history/sendForApproval';
import DatePicker from 'vue2-datepicker';
import store from '../../../../store';
import AdvanceSearch from './advanceSearch';
import PrintWorklfow from './addInternalWorkflow/printInternalWorkflow';
import openKmAuthentication from './openKmAuthentication';
import actionTaken from './actionTaken';
export default {
  name: 'internalWorkFlow',
  components: {
    DatePicker,
    addInternalWorkflow,
    HistoryRecord,
    ApprovalHistory,
    UploadDocument,
    AdvanceSearch,
    sendForApproval,
    PrintWorklfow,
    openKmAuthentication,
    actionTaken
  },
  watch: {
    currentPage: function() {
      this.getWorflowList();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getWorflowList();
    }
  },
  data() {
    return {
      openkmAuthToken: null,
      showOpenKmModal: false,
      wfEventId: null,
      approvalHistoryList: [],
      showPrintModal: false,
      isSuccess: false,
      showAlert: false,
      payload: {},
      respMessage: null,
      actionTakenModal: false,
      wfHeaderId: null,
      showHistoryBtn: false,
      showAdvSearchModal: false,
      selectedGridData: null,
      showValueSetModal: null,
      parent_value_set_id: null,
      setTimeVsetCode: null,
      valueSetCode: null,
      historyType: null,
      showHistory: false,
      unsubscribe: null,
      loader: false,
      showAddIntWorkflow: false,
      pageOptions: commonHelper.getPageOption(),
      perPage: commonHelper.perPageRecord,
      valueSetName: null,
      currentPage: 1,
      totalRows: null,
      searchForm: {
        number: null,
        fromDate: null,
        toDate: null,
        start_date_end_date: [],
        type: {
          value_code: null,
          value_meaning: null,
          value_set_dtl_id: null
        },
        category: {
          value_code: null,
          value_meaning: null,
          value_set_dtl_id: null
        },
        subcategory: {
          value_code: null,
          value_meaning: null,
          value_set_dtl_id: null
        },
        legalEntity: {
          leName: null,
          leId: null
        },
        projectType: {
          value_code: null,
          value_meaning: null
        },
        localtion: {
          locName: null,
          locId: null
        },
        actionTaken: false,
        subject: null
      },
      workflowList: [],
      workflowField: [
        {
          key: 'select'
        },
        {
          key: 'nfa_number',
          label: 'Number'
        },
        {
          key: 'nfa_type',
          label: 'Type'
        },
        {
          key: 'nfa_cat_vset',
          label: 'Category'
        },
        {
          key: 'nfa_sub_cat_vset',
          label: 'Sub Category'
        },
        {
          key: 'le_name',
          label: 'Legal Entity'
        },
        {
          key: 'budget_amount',
          label: 'Required Amount'
        },
        {
          key: 'awarded_amount',
          label: 'Approved Amount'
        },
        {
          key: 'creation_date'
        },
        {
          key: 'approval_status',
          label: 'Status'
        },
        {
          key: 'action'
        }
      ],
      action: {
        date: null,
        ref: null,
        comments: null
      }
    };
  },
  mounted() {
    this.getEventId();
    this.getWorflowList();
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'add' && !this.actionTakenModal) {
          this.selectedGridData = null;
          this.showAddIntWorkflow = true;
        }
        if (actionName === 'save' && this.actionTakenModal) {
          this.$refs.actionTaken.saveActionTaken();
        } else if (actionName === 'update' && this.actionTakenModal) {
          this.$refs.actionTaken.editMode = true;
        }
        if (
          actionName === 'download' &&
          !this.showAddIntWorkflow &&
          !this.actionTakenModal
        ) {
          this.loader = true;
          /**
           * @param(payload, 'action name', 'file name')
           */
          const downloadpayload = { ...this.payload };
          downloadpayload._limit = this.totalRows;
          this.downloadExcel.downloadData(
            downloadpayload,
            'intWorkflow/getIntWorkflowList',
            'interworkflow',
            () => (this.loader = false)
          );
        }
      }
    });
  },
  methods: {
    getWorflowList() {
      this.payload = {
        _page: this.currentPage - 1,
        _limit: this.perPage,
        menu_id: store.state.shared.menuId,
        type: this.searchForm.type.value_code,
        number: this.searchForm.number,
        category: this.searchForm.category.value_code,
        sub_category: this.searchForm.subcategory.value_code,
        from_date: commonHelper.formattedDate(this.searchForm.start_date_end_date[0]),
        to_date: commonHelper.formattedDate(this.searchForm.start_date_end_date[1]),
     
        subject: this.searchForm.subject,
        le_id: this.searchForm.legalEntity.leId,
        location_id: this.searchForm.localtion.locId,
        project_id: '',
        hr_org_id: '',
        action_taken: this.searchForm.actionTaken
      };
      this.loader = true;
      this.$store
        .dispatch('intWorkflow/getIntWorkflowList', this.payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.workflowList = response.data.data.page;
            this.totalRows = response.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        })
        .finally(() => {
          this.loader = false;
        });
    },
    search() {},
    clear() {
      this.searchForm.type.value_code = null;
      this.searchForm.type.value_meaning = null;
      this.searchForm.fromDate = null;
      this.searchForm.toDate = null;
      this.searchForm.type.value_code, (this.searchForm.number = null);
      this.searchForm.category.value_code = null;
      this.searchForm.subcategory.value_code = null;
      this.searchForm.subject = null;
      this.searchForm.legalEntity.leId = null;
      this.searchForm.localtion.locId = null;
      this.searchForm.actionTaken = null;
      this.searchForm.start_date_end_date = [];
      this.getWorflowList();
    },
    hideIntWorkflow() {
      this.showAddIntWorkflow = false;
    },
    showHideHistory(flag, historyType) {
      this.showHistory = flag;
      this.historyType = historyType;
    },
    openValueSetModal(vsetCode) {
      this.valueSetCode = vsetCode;
      this.showValueSetModal = true;
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    selectedvalueSet(item) {
      if (this.valueSetCode === 'NFA_TYPE') {
        this.searchForm.type.value_code = item.value_code;
        this.searchForm.type.value_meaning = item.value_meaning;
        this.searchForm.type.value_set_dtl_id = item.value_set_dtl_id;
      }
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    rowSelected(item) {
      this.selectedGridData = item;
      this.wfHeaderId = item.nfa_hdr_id;
      this.showAddIntWorkflow = true;
    },
    showHideAdvSearch(flag) {
      this.showAdvSearchModal = flag;
    },
    selectedWfId(item) {
      this.wfHeaderId = item.nfa_hdr_id;
      this.selectedGridData = item;
      this.showHistoryBtn = true;
    },
    advanceSearch(searchParams) {
      this.showAdvSearchModal = false;
      this.searchForm.type.value_code = searchParams.type.value_code;
      this.searchForm.category.value_code = searchParams.category.value_code;
      this.searchForm.number = searchParams.number;
      this.searchForm.subcategory.value_code = searchParams.subcategory.value_code;
      this.searchForm.start_date_end_date = searchParams.start_date_end_date;

      this.searchForm.subject = searchParams.subject;
      this.searchForm.legalEntity.leId = searchParams.legalEntity.leId;
      this.searchForm.localtion.locId = searchParams.localtion.locId;
      this.searchForm.actionTaken = searchParams.actionTaken;
      this.getWorflowList();
    },
    showHideActionTake(flag, item) {
      this.selectedGridData = item;
      this.actionTakenModal = flag;
      this.wfHeaderId = item.nfa_hdr_id;
    },
    async copyWorkflow() {
      const promt = confirm('Are you sure, you want to create copy?');
      if (promt) {
        try {
          this.loader = true;
          //location
          const locPayload = {
            tabType: 'locations',
            wfHeaderId: this.wfHeaderId
          };
          const locResp = await this.$store.dispatch(
            'intWorkflow/getWfTabsDetails',
            locPayload
          );
          const filterLocation = locResp.data.data.filter(
            data => data.location_id !== null
          );
          const localtionDetails = filterLocation.map(data => {
            return {
              location_id: data.location_id
            };
          });
          //Department
          const depPayload = {
            tabType: 'departments',
            wfHeaderId: this.wfHeaderId
          };
          const depResp = await this.$store.dispatch(
            'intWorkflow/getWfTabsDetails',
            depPayload
          );
          const filteredDep = depResp.data.data.filter(
            data => data.key_flexfield_comb_id !== null
          );
          const departmentDetails = filteredDep.map(data => {
            return {
              department_id: data.key_flexfield_comb_id
            };
          });
          //Project
          const projPayload = {
            tabType: 'projects',
            wfHeaderId: this.wfHeaderId
          };
          const projResp = await this.$store.dispatch(
            'intWorkflow/getWfTabsDetails',
            projPayload
          );
          const filterProj = projResp.data.data.filter(
            data => data.entity_type_vset !== null
          );
          const projectDetails = filterProj.map(data => {
            return {
              entity_type: data.entity_type_vset,
              floor_id: data.floor_id,
              pocket_id: data.pocket_id,
              project_id: data.prj_id,
              sector_id: data.sector_id,
              tower_id: data.tower_id,
              unit_id: data.unit_id,
              waivers: this.getWaiverData(data.waivers)
            };
          });
          //Negatiated
          const negPayload = {
            tabType: 'negotiated-by',
            wfHeaderId: this.wfHeaderId
          };
          const negResp = await this.$store.dispatch(
            'intWorkflow/getWfTabsDetails',
            negPayload
          );
          const filteredNego = negResp.data.data.filter(
            data => data.employee_id !== null
          );
          const negotiated = filteredNego.map(data => {
            return {
              comments: data.remarks,
              negotiate_id: data.employee_id,
              primary_flag: data.primary_flag
            };
          });
          //Initiated
          const initPayload = {
            tabType: 'initiated-by',
            wfHeaderId: this.wfHeaderId
          };
          const initResp = await this.$store.dispatch(
            'intWorkflow/getWfTabsDetails',
            initPayload
          );
          const filteredInit = initResp.data.data.filter(
            data => data.employee_id !== null
          );
          const initiatedDetails = filteredInit.map(data => {
            return {
              initiator_id: data.employee_id
            };
          });
          if (
            this.selectedGridData.budget_amount !== null ||
            this.selectedGridData.budget_amount !== ''
          ) {
            let budgetAmt = this.selectedGridData.budget_amount;
            const newBudgetAmt = budgetAmt.replace(/,/g, '');
            this.selectedGridData.budget_amount = newBudgetAmt;

            if (
              this.selectedGridData.awarded_amount !== null ||
              this.selectedGridData.awarded_amount !== ''
            ) {
              let awardedAmt = this.selectedGridData.awarded_amount;
              const newAwardedAmt = awardedAmt.replace(/,/g, '');
              this.selectedGridData.awarded_amount = newAwardedAmt;
            }
          } else {
            if (
              this.selectedGridData.budget_amount !== null ||
              this.selectedGridData.budget_amount !== ''
            ) {
              this.selectedGridData.budget_amount = '00.00';
            } else if (
              this.selectedGridData.awarded_amount !== null ||
              this.selectedGridData.awarded_amount !== ''
            ) {
              this.selectedGridData.awarded_amount = '00.00';
            }
          }
          const payload = {
            nfa_hdr_id: 0,
            nfa_type_vset: this.selectedGridData.nfa_type_vset,
            nfa_cat_vset: this.selectedGridData.nfa_cat_vset,
            nfa_sub_cat_vset: this.selectedGridData.nfa_sub_cat_vset,
            le_id: this.selectedGridData.le_id,
            expected_approval_date: this.selectedGridData
              .expected_approval_date,
            urgent: this.selectedGridData.urgent === '0' ? false : true,
            urgent_comment: this.selectedGridData.urgent_comment,
            nfa_subject: this.selectedGridData.nfa_subject,
            body: this.selectedGridData.body,
            changes: this.selectedGridData.changes,
            excptions: this.selectedGridData.excptions,
            current_flag: this.selectedGridData.current_flag,
            change_flag: this.selectedGridData.change_flag,
            exception_flag: this.selectedGridData.exception_flag,
            transaction_currency: this.selectedGridData.transaction_currency,
            base_currency: this.selectedGridData.transaction_currency,
            budget_amount: this.selectedGridData.budget_amount,
            exchange_rate: 1,
            awarded_amount: this.selectedGridData.awarded_amount,
            bidding_type: this.selectedGridData.bidding_type,
            budget_ref_code: this.selectedGridData.budget_ref_code,
            departments:
              departmentDetails && departmentDetails.length > 0
                ? departmentDetails
                : null,
            event_id: this.wfEventId,
            initiators:
              initiatedDetails && initiatedDetails.length > 0
                ? initiatedDetails
                : null,
            locations:
              localtionDetails && localtionDetails.length > 0
                ? localtionDetails
                : null,
            menu_id: this.$store.state.shared.menuId,
            nature_of_purchase: this.selectedGridData.nature_of_purchase,
            negotiates: negotiated && negotiated.length > 0 ? negotiated : null,
            project_type_vset: this.selectedGridData.project_type_vset,
            projects:
              projectDetails && projectDetails.length > 0
                ? projectDetails
                : null,
            request_for: ''
          };
          this.$store
            .dispatch('intWorkflow/addEditIntWorkflow', payload)
            .then(response => {
              this.showAlert = true;
              if (response.status === 200) {
                this.getWorflowList();
                this.loader = false;
                this.selectedGridData = response.data.data;
                this.wfHeaderId = response.data.data.nfa_hdr_id;
                this.showAddIntWorkflow = true;
                this.editMode = false;
                this.isSuccess = true;
                this.respMessage = response.data.message;
              } else {
                this.loader = false;
                this.isSuccess = false;
                this.respMessage = response.response.data.message;
              }
            })
            .catch(() => {
              this.loader = false;
            });
        } catch (err) {
          this.loader = false;
          alert(err);
        }
      }
    },
    getWaiverData(waiverList) {
      const filterNullData = waiverList.filter(
        data => data.waiver_vset !== null
      );
      const getWaiverAmount = filterNullData.map(waiv => {
        return {
          waiver_amount: waiv.waiver_amount,
          waiver_vset: waiv.waiver_vset
        };
      });
      return getWaiverAmount && getWaiverAmount.length ? getWaiverAmount : [];
    },
    async printIntWorkflow() {
      await this.$htmlToPaper('print-workflow', {
        windowTitle: 'Internal Workflow'
      });
    },
    showHidePrint(flag) {
      if (flag) {
        const payload = {
          nfaHdrId: this.selectedGridData.nfa_hdr_id,
          eventId: this.wfEventId
        };
        this.loader = true;
        this.$store
          .dispatch('intWorkflow/getApprovalList', payload)
          .then(response => {
            this.loader = false;
            if (response.status === 200) {
              this.approvalHistoryList = response.data.data;
              this.showPrintModal = flag;
            }
          })
          .catch(() => {
            this.loader = false;
          });
      } else {
        this.showPrintModal = flag;
      }
    },
    getEventId() {
      this.$store
        .dispatch('intWorkflow/getEventId', store.state.shared.menuId)
        .then(response => {
          if (response.status === 200) {
            this.wfEventId = response.data.data;
          }
        });
    },
    checkOpenKmPasswordSaved() {
      this.loader = true;
      this.$store
        .dispatch('intWorkflow/checkSavedPassword')
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            if (response.data.data) {
              this.getOpenKmAuthToken();
            } else {
              this.showHideOpenKmModal(true);
            }
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getOpenKmAuthToken() {
      const payload = {
        password: null,
        saved_password_flag: true,
        user_name: null
      };
      this.loader = true;
      this.$store
        .dispatch('intWorkflow/openKmAuthenticate', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.openkmAuthToken = response.data.data.token;
            this.showHideHistory(true, 'uploadDocument');
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    showHideOpenKmModal(flag) {
      this.showOpenKmModal = flag;
    },
    setOpenKmToken(token) {
      this.showHideOpenKmModal(false);
      this.openkmAuthToken = token;
      this.showHideHistory(true, 'uploadDocument');
    },
    clearVsetValues(valueSetCode){
      if (valueSetCode === 'NFA_TYPE') {
        this.searchForm.type.value_code = null;
        this.searchForm.type.value_meaning = null;
        this.searchForm.type.value_set_dtl_id = null;
      }
    }
  },
  beforeDestroy() {
    clearTimeout(this.setTimeVsetCode);
    this.unsubscribe();
  }
};
